<template>
  <div>
    <e-charts :options="options" class="chart" v-if="xAxisData.length" />
    <not-data v-else></not-data>
  </div>
</template>

<script>
import "echarts/lib/chart/line";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";
import notDataVue from "../not-data";

export default {
  name: "line-chart",
  components: {
    [notDataVue.name]: notDataVue, // 无数据
  },
  props: {
    data: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
    /**
     * 鼠标移入条形图提示
     */
    formatterFn: {
      type: Function,
      default: function (params) {
        if (params) {
          let res = params[0].name + "</br>";
          for (let i in params) {
            res +=
              params[i].marker +
              params[i].seriesName +
              ":" +
              params[i].data +
              "</br>";
          }
          return res;
        }
      },
    },
    /**
     * y轴标题显示位置
     */
    alignY: {
      type: String,
      default: "center",
    },
    /**
     * 单位
     */
    unit: {
      type: String,
      default: "",
    },
  },
  created() {},
  data() {
    return {};
  },
  computed: {
    // 使用率分析折线图配置
    options() {
      return {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          formatter: this.formatterFn, // --提示浮窗
          cursor: "pointer",
        },
        legend: {
          bottom: "bottom",
          data: this.data.legendArr, // --legend
          icon: "rect",
          itemHeight: 2,
          itemWidth: 12,
          textStyle: {
            fontSize: 12, // 字体大小
            color: "#8C8C8C", // 字体颜色
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          // boundaryGap: false,
          data: this.data.xAxisData, // --横坐标
          axisLine: {
            lineStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(0, 0, 0, 0.45)", // 更改坐标轴文字颜色
              fontSize: 12, // 更改坐标轴文字大小
            },
          },
        },
        yAxis: {
          type: "value",
          name:
            this.$t("el.dataAnalysis.unit") +
            this.$t("el.symbol.colon") +
            (this.unit || this.$t("el.MyHome.minutes")),
          nameTextStyle: {
            color: "rgba(0, 0, 0, .45)",
            fontSize: 12,
            padding: [0, 0, 20, 0],
            align: this.alignY,
          },
          axisTick: {
            // y轴刻度线
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(0, 0, 0, 0.45)", // 更改坐标轴文字颜色
              fontSize: 12, // 更改坐标轴文字大小
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
        },
        series: this.seriesArr, // --折线数据
      };
    },
    /**
     * x轴数据
     */
    xAxisData() {
      return (
        (this.data &&
          Array.isArray(this.data.xAxisData) &&
          this.data.xAxisData) ||
        []
      );
    },
    /**
     * 折线数据
     */
    seriesArr() {
      let arr = [];
      let { legendArr, yAxisDataColor, yAxisData } = this.data;
      yAxisData.forEach((item, index) => {
        arr.push({
          name: legendArr[index],
          type: "line",
          data: yAxisData[index],
          lineStyle: {
            color: yAxisDataColor[index], // 改变折线颜色
          },
          itemStyle: {
            normal: {
              color: yAxisDataColor[index],
              label: {
                show: true,
                formatter: "{c}",
                textStyle: {
                  color: "#595959",
                  fontSize: 12,
                },
              },
            },
          },
          symbolSize: 10,
        });
      });
      return arr;
    },
  },
};
</script>

<style lang="less" scoped>
.chart {
  width: 100%;
  height: 420px;
}
</style>
