<template>
  <div class="usage">
    <div class="analysis-header rank-plate">
      <analyze-date-range
        @change="onChangeDateRange"
        :defaultDateRange="this.$store.state.otherParams"
        @changeSchool="onChangeSchool"
      ></analyze-date-range>
    </div>
    <!-- 使用率分析 -->
    <div class="usage_box">
      <div class="usage_title">
        <analysis-title before-icon="icon-tit-line">
          {{ $t("el.dataAnalysis.usageAnalysis") }}
          <div slot="content">
            <p
              v-for="(item, index) in $t('el.dataAnalysis.usageAnalysisDesc')"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
        </analysis-title>
      </div>
      <line-chart
        :data="usageData"
        :formatterFn="formatterFn"
        unit="%"
      ></line-chart>
    </div>
    <!-- 人均使用时长分析 -->
    <div class="usage_box">
      <div class="usage_title flex">
        <analysis-title class="usage_title_left" before-icon="icon-tit-line">
          {{ $t("el.dataAnalysis.usageTimeAnalysis") }}
          <div slot="content">
            <p
              v-for="(item, index) in $t(
                'el.dataAnalysis.usageTimeAnalysisDesc'
              )"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
        </analysis-title>
        <div class="usage_title_right">
          <choose-type
            @changeChooseType="changeChooseType($event)"
            :chooseType="chooseType"
            :chooseOptions="chooseTypeOptions"
          ></choose-type>
        </div>
      </div>
      <line-chart :data="durationData"></line-chart>
    </div>
    <!-- 未使用系统 -->
    <div v-loading="noUseLoading" class="usage_box">
      <div class="usage_title flex">
        <analysis-title
          class="usage_title_left"
          before-icon="icon-tit-table"
          after-icon
        >
          {{ $t("el.dataAnalysis.unuseSystemTeacher") }}
        </analysis-title>
        <el-button
          v-if="noUseSystem.length > 0"
          style="margin-right: 20px; margin-left: auto"
          type="primary"
          size="medium"
          @click="onHandleDownLoadTable('noUse')"
          >{{ $t("el.schoolResourceManage.derivedForm") }}</el-button
        >
        <toggle-list
          @showAll="showAll"
          :showToggle="noUseSystem.length >= 6 || totalNoUsePage > 1"
          type="noUse"
        ></toggle-list>
      </div>
      <table-list
        :list="noUseSystem"
        :total="totalNoUse"
        :totalPage="totalNoUsePage"
        :showPage="showAllNoUse"
        @handleCurrentChange="handleCurrentChange($event, 'noUse')"
        @handleSizeChange="handleSizeChange($event, 'noUse')"
      ></table-list>
    </div>
    <!-- 未备课 -->
    <div v-loading="prepareLoading" class="usage_box">
      <div class="usage_title flex">
        <analysis-title
          class="usage_title_left"
          before-icon="icon-tit-table"
          after-icon
        >
          {{ $t("el.dataAnalysis.unuseSystemClientTeacher") }}
        </analysis-title>
        <el-button
          v-if="noPrepare.length > 0"
          style="margin-right: 20px; margin-left: auto"
          type="primary"
          size="medium"
          @click="onHandleDownLoadTable('prepare')"
          >{{ $t("el.schoolResourceManage.derivedForm") }}</el-button
        >
        <toggle-list
          @showAll="showAll"
          :showToggle="noPrepare.length >= 6 || totalPreparePage > 1"
          type="prepare"
        ></toggle-list>
      </div>
      <table-list
        :list="noPrepare"
        :total="totalPrepare"
        :totalPage="totalPreparePage"
        :showPage="showAllPrepare"
        @handleCurrentChange="handleCurrentChange($event, 'prepare')"
        @handleSizeChange="handleSizeChange($event, 'prepare')"
      ></table-list>
    </div>
    <!-- 未授课 -->
    <div v-loading="teachLoading" class="usage_box">
      <div class="usage_title flex">
        <analysis-title
          class="usage_title_left"
          before-icon="icon-tit-table"
          after-icon
        >
          {{ $t("el.dataAnalysis.unuseSystemTeachingTeacher") }}
        </analysis-title>
        <el-button
          v-if="noTeach.length > 0"
          style="margin-right: 20px; margin-left: auto"
          type="primary"
          size="medium"
          @click="onHandleDownLoadTable('teach')"
          >{{ $t("el.schoolResourceManage.derivedForm") }}</el-button
        >
        <toggle-list
          @showAll="showAll"
          :showToggle="noTeach.length >= 6 || totalTeachPage > 1"
          type="teach"
        ></toggle-list>
      </div>
      <table-list
        :list="noTeach"
        :total="totalTeach"
        :totalPage="totalTeachPage"
        :showPage="showAllTeach"
        @handleCurrentChange="handleCurrentChange($event, 'teach')"
        @handleSizeChange="handleSizeChange($event, 'teach')"
      ></table-list>
    </div>
  </div>
</template>

<script>
import analysisTitleVue from "@/components/teaching-components-chart/analysis-title/index.vue";
import analyzeDateRangeVue from "@/components/teaching-components-chart/analyze-date-range/index.vue";
import chooseTypeVue from "@/components/teaching-components-chart/choose-type/index.vue";
import tableListVue from "./children/table-list.vue";
import toggleListVue from "./children/toggle-list.vue";
import notDataVue from "@/components/teaching-components-chart/not-data/index.vue";
import lineChart from "@/components/teaching-components-chart/line-chart/index.vue";

import {
  getRate,
  getAvgTime,
  noUseTeacher,
  noUseSystemTeacher,
} from "@/api/manage/usage-analyze";
import { genQuickDateRange } from "@/utils";
import { exportDownCommon } from "../../../../utils";

export default {
  name: "usage",
  // 注册组件
  components: {
    [analyzeDateRangeVue.name]: analyzeDateRangeVue, // 日期范围组件
    [chooseTypeVue.name]: chooseTypeVue, // 选项组件
    [tableListVue.name]: tableListVue, // 表格组件
    [notDataVue.name]: notDataVue, // 无数据
    [analysisTitleVue.name]: analysisTitleVue, // 图表标题组件
    [lineChart.name]: lineChart, // 折线图表
    [toggleListVue.name]: toggleListVue, // 折线图表
  },
  data() {
    return {
      showAllPrepare: false, // 默认备课table不显示页
      showAllNoUse: false, // 默认系统使用table不显示页
      showAllTeach: false, // 默认授课table不显示页
      chooseType: "days", // 选中类型
      chooseTypeOptions: [], // 选项类型
      dateArrRate: [], // 使用情况分析-横坐标
      yAxisData: [], // 使用情况分析-折线
      rateArr: [], // 使用情况分析-总数据
      dateArrDuration: [], // 人均使用时长-横坐标
      yAxisDataDuation: [], // 人均使用时长-折线
      avgTime: [], // 人均使用时长分析-总数据
      timeParams: {
        startDate: "",
        endDate: "",
      },
      noPrepareTeacherList: [], // 未备课老师
      noUseSystemTeacherList: [], // 未使用系统老师
      noPrepare: [], // 显示的未备课老师
      noUseSystem: [], // 显示的未使用系统的老师
      pagingPrepareParams: {
        // 未备课老师页数
        limit: 20,
        offset: 0,
        page: 1,
      },
      pagingNoUseParams: {
        // 未使用系统老师页数
        limit: 20,
        offset: 0,
        page: 1,
      },
      totalPrepare: 0, // 未备课老师总数
      totalNoUse: 0, // 未使用系统老师总数
      totalPreparePage: 0, // 未备课老师总页数
      totalNoUsePage: 0, // 未使用系统老师总页数
      noTeachTeacherList: [], // -----未授课
      noTeach: [],
      pagingTeachParams: {
        limit: 20,
        offset: 0,
        page: 1,
      },
      totalTeach: 0,
      totalTeachPage: 0,
      prepareLoading: false,
      teachLoading: false,
      noUseLoading: false,
    };
  },
  created() {
    // this.getNoUsePrepareTeacher();
    // this.getNoUseTeachTeacher();
    // 初始化默认值
    if (
      this.$store.state.otherParams &&
      this.$store.state.otherParams.length === 2
    ) {
      console.log("333333-======");
      this.onChangeDateRange(this.$store.state.otherParams);
    }
  },
  watch: {
    // "$i18n.locale": {
    //   immediate: true,
    //   handler(val, old) {
    //     if (this.$store.state.otherParams) {
    //       if (isNaN(this.$store.state.otherParams)) {
    //         this.onChangeDateRange(this.$store.state.otherParams);
    //       } else {
    //         this.onChangeDateRange(
    //           genQuickDateRange(this.$store.state.otherParams)
    //         );
    //       }
    //     } else {
    //       this.onChangeDateRange(genQuickDateRange(7));
    //     }
    //   },
    // },
  },
  computed: {
    usageData() {
      return {
        xAxisData: this.dateArrRate,
        yAxisDataColor: ["#ed1f1f", "#FF9E35", "#54C336"],
        yAxisData: this.yAxisData,
        legendArr: [
          this.$t("el.dataAnalysis.platformUsage"),
          this.$t("el.dataAnalysis.preparingUsage"),
          this.$t("el.dataAnalysis.teachingUtilization"),
        ],
      };
    },
    durationData() {
      return {
        xAxisData: this.dateArrDuration,
        yAxisDataColor: ["#FF9E35", "#54C336"],
        yAxisData: this.yAxisDataDuation,
        legendArr: [
          this.$t("el.dataAnalysis.perPreparationTime"),
          this.$t("el.dataAnalysis.perteachingTime"),
        ],
      };
    },
  },
  methods: {
    /**
     * 日期选择
     */
    onChangeDateRange(val) {
      // 校验数据
      if (!val && val.length > 2) return console.warn("参数错误");
      this.timeParams.startDate = this.$dateFormat(
        val[0],
        "yyyy-MM-dd HH:mm:ss"
      );
      this.timeParams.endDate =
        this.$dateFormat(val[1], "yyyy-MM-dd") + " 23:59:59";
      if (val && val.length >= 2) {
        let times = val[1].getTime() - val[0].getTime();
        let days = times / 1000 / 60 / 60 / 24;
        if (days < 14) {
          this.chooseTypeOptions = [
            {
              label: "days",
              value: this.$t("el.dataAnalysis.day"),
            },
          ];
        } else {
          if (
            this.moreThree(this.timeParams.startDate, this.timeParams.endDate)
          ) {
            this.chooseTypeOptions = [
              {
                label: "days",
                value: this.$t("el.dataAnalysis.day"),
              },
              {
                label: "weeks",
                value: this.$t("el.dataAnalysis.weeks"),
              },
              {
                label: "months",
                value: this.$t("el.dataAnalysis.month"),
              },
            ];
          } else {
            this.chooseTypeOptions = [
              {
                label: "days",
                value: this.$t("el.dataAnalysis.day"),
              },
              {
                label: "weeks",
                value: this.$t("el.dataAnalysis.weeks"),
              },
            ];
          }
        }
        this.chooseType = "days";
      }
      if (this.$store.state.schoolCode) {
        this.timeParams = {
          ...this.timeParams,
          tenantId: this.$store.state.schoolCode, // 学校编码
        };
      }
      this.getRate();
      getAvgTime(Object.assign({ useType: 0 }, this.timeParams)).then((res) => {
        this.avgTime = res.data;
        this.getAvgTime(this.chooseType);
      });
      this.getNoUseSystemTeacher();
      this.getNoUsePrepareTeacher();
      this.getNoUseTeachTeacher();
    },
    /**
     * >=3个月
     */
    moreThree(date1, date2) {
      let arr1 = date1.split("-");
      let arr2 = date2.split("-");
      let flag = false;
      if (arr1[0] == arr2[0]) {
        if (arr2[1] - arr1[1] > 1) flag = true;
      } else if (arr1[0] + 1 == arr2[0]) {
        if (arr2[1] + (12 - arr1[1]) > 1) flag = true;
      } else if (arr1[0] + 1 < arr2[0]) {
        flag = true;
      }
      return flag;
    },
    /**
     * 学校 选择
     */
    onChangeSchool(schoolCode) {
      this.timeParams = {
        ...this.timeParams,
        tenantId: schoolCode, // 学校编码
      };
      this.getRate();
      getAvgTime(Object.assign({ useType: 0 }, this.timeParams)).then((res) => {
        this.avgTime = res.data;
        this.getAvgTime(this.chooseType);
      });
      this.getNoUseSystemTeacher();
      this.getNoUsePrepareTeacher();
      this.getNoUseTeachTeacher();
      this.$emit("changeSchool", schoolCode);
    },
    /**
     * 日 周 月 选择
     */
    changeChooseType(type) {
      this.chooseType = type;
      this.getAvgTime(type);
    },
    /**
     * 使用情况分析 数据
     */
    getRate() {
      getRate(Object.assign({ useType: 0 }, this.timeParams)).then((res) => {
        let data = res.data;
        this.rateArr = data;
        this.dateArrRate = [];
        this.yAxisData = [];
        let yAxisDataL = [];
        let yAxisDataP = [];
        let yAxisDataT = [];
        data.forEach((item, index) => {
          this.dateArrRate.push(item.statisticalDate);
          yAxisDataL.push(item.loginRate);
          yAxisDataP.push(item.prepareRate);
          yAxisDataT.push(item.teachRate);
        });
        this.yAxisData = [yAxisDataL, yAxisDataP, yAxisDataT];
      });
    },
    /**
     * 人均使用时长分析 数据
     */
    getAvgTime(type) {
      this.dateArrDuration = [];
      this.yAxisDataDuation = [];
      let yAxisDataDuationP = [];
      let yAxisDataDuationT = [];

      this.avgTime[type].forEach((item, index) => {
        if (type === "days") {
          this.dateArrDuration.push(item.statisticalDate);
        } else {
          this.dateArrDuration.push(item.xDate.replace("到", "-"));
        }
        yAxisDataDuationP.push(item.avgPrepareTime);
        yAxisDataDuationT.push(item.avgTeachTime);
      });
      this.yAxisDataDuation = [yAxisDataDuationP, yAxisDataDuationT];
    },
    /**
     * 鼠标移入条形图提示
     */
    formatterFn(params) {
      let tooltipObj = this.rateArr.find((item) => {
        return item.statisticalDate == params[0].name;
      });
      let res = params[0].name + "</br>";
      res +=
        params[0].marker +
        params[0].seriesName +
        ":" +
        params[0].data +
        "%</br>";
      res +=
        params[0].marker +
        this.$t("el.common.numberOf") +
        params[0].seriesName.split("率")[0] +
        this.$t("el.common.numberPeople") +
        ":" +
        tooltipObj.teachLoginNum +
        "</br>";
      res +=
        params[1].marker +
        params[1].seriesName +
        ":" +
        params[1].data +
        "%</br>";
      res +=
        params[1].marker +
        this.$t("el.common.numberOf") +
        params[1].seriesName.split("率")[0] +
        this.$t("el.common.numberPeople") +
        ":" +
        tooltipObj.teachPrepareNum +
        "</br>";
      res +=
        params[2].marker +
        params[2].seriesName +
        ":" +
        params[2].data +
        "%</br>";
      res +=
        params[2].marker +
        this.$t("el.common.numberOf") +
        params[2].seriesName.split("率")[0] +
        this.$t("el.common.numberPeople") +
        ":" +
        tooltipObj.teachTeachNum +
        "</br>";
      return res;
    },

    async onHandleDownLoadTable(type) {
      if (type === "noUse") {
        let params = Object.assign(
          this.pagingNoUseParams,
          { useType: 0 },
          this.timeParams,
          this.otherParams
        );
        this.noUseLoading = true;
        try {
          await exportDownCommon(
            "get",
            params,
            "/bi/export/detailUse/noUsePlatform",
            `未使用系统老师名单_${new Date().getTime()}.xlsx`
          );
          this.noUseLoading = false;
        } catch (error) {
          this.noUseLoading = false;
        }
      } else if (type === "prepare") {
        let params = Object.assign(
          this.pagingPrepareParams,
          { useType: 0 },
          this.timeParams,
          this.otherParams
        );
        this.prepareLoading = true;
        try {
          await exportDownCommon(
            "get",
            params,
            "/bi/export/dailyUse/noUseTeacher",
            `未使用备课系统的老师_${new Date().getTime()}.xlsx`
          );
          this.prepareLoading = false;
        } catch (error) {
          this.prepareLoading = false;
        }
      } else {
        let params = Object.assign(
          this.pagingTeachParams,
          { useType: 1 },
          this.timeParams,
          this.otherParams
        );
        this.teachLoading = true;
        try {
          await exportDownCommon(
            "get",
            params,
            "/bi/export/dailyUse/noUseTeacher",
            `未使用授课系统的老师_${new Date().getTime()}.xlsx`
          );
          this.teachLoading = false;
        } catch (error) {
          this.teachLoading = false;
        }
      }
    },

    /**
     * 分页
     */
    handleCurrentChange(val, type) {
      if (type === "prepare") {
        this.pagingPrepareParams.page = val;
        this.pagingPrepareParams.offset =
          (val - 1) * this.pagingPrepareParams.limit;
        this.getNoUsePrepareTeacher();
      } else if (type === "teach") {
        this.pagingTeachParams.page = val;
        this.pagingTeachParams.offset =
          (val - 1) * this.pagingTeachParams.limit;
        this.getNoUseTeachTeacher();
      } else {
        this.pagingNoUseParams.page = val;
        this.pagingNoUseParams.offset =
          (val - 1) * this.pagingNoUseParams.limit;
        this.getNoUseSystemTeacher();
      }
    },
    async handleSizeChange(val, type) {
      if (type === "prepare") {
        this.pagingPrepareParams.page = 1;
        this.pagingPrepareParams.limit = val.limit;
        this.pagingPrepareParams.offset = 0;
        this.getNoUsePrepareTeacher();
      } else if (type === "teach") {
        this.pagingTeachParams.page = 1;
        this.pagingTeachParams.limit = val.limit;
        this.pagingTeachParams.offset = 0;
        this.getNoUseTeachTeacher();
      } else {
        this.pagingNoUseParams.page = 1;
        this.pagingNoUseParams.limit = val.limit;
        this.pagingNoUseParams.offset = 0;
        this.getNoUseSystemTeacher();
      }
    },

    /**
     * 展示全部名单
     */
    showAll(type, show) {
      if (type === "prepare") {
        this.showAllPrepare = show;
        this.noPrepare = this.nameListFn(this.noPrepareTeacherList, show);
      } else if (type === "noUse") {
        this.showAllNoUse = show;
        this.noUseSystem = this.nameListFn(this.noUseSystemTeacherList, show);
      } else {
        this.showAllTeach = show;
        this.noTeach = this.nameListFn(this.noTeachTeacherList, show);
      }
    },

    /**
     * 教师列表处理
     */
    nameListFn(nameList, show) {
      if (show) {
        if (nameList.length % 2 !== 0) {
          nameList.push({
            staffName: "",
            staffCode: "",
          });
        }
      } else {
        if (nameList.length > 6) {
          nameList = nameList.slice(0, 5);
          nameList.push({
            staffName: "......",
            staffCode: "......",
          });
        } else {
          if (nameList.length % 2 !== 0) {
            nameList.push({
              staffName: "",
              staffCode: "",
            });
          }
        }
      }
      return nameList;
    },
    /**
     * 未使用系统
     */
    async getNoUseSystemTeacher() {
      let params = Object.assign(
        this.pagingNoUseParams,
        { useType: 0 },
        this.timeParams
      );
      this.noUseLoading = true;
      await noUseSystemTeacher(params).then((res) => {
        this.totalNoUse = res.data.total;
        this.totalNoUsePage = res.data.pages;
        this.noUseSystemTeacherList = (res.data.records || []).map((item) => {
          item._mobile = item.staffPhone;
          return item;
        });
        this.noUseSystem = this.nameListFn(
          this.noUseSystemTeacherList,
          this.showAllNoUse
        );
      });
      this.noUseLoading = false;
    },
    /**
     * 未备课
     */
    async getNoUsePrepareTeacher() {
      let params = Object.assign(
        this.pagingPrepareParams,
        { useType: 0 },
        this.timeParams
      );
      this.prepareLoading = true;
      await noUseTeacher(params).then((res) => {
        this.totalPrepare = res.data.total;
        this.totalPreparePage = res.data.pages;
        this.noPrepareTeacherList = (res.data.records || []).map((item) => {
          item._mobile = item.mobile;
          return item;
        });
        this.noPrepare = this.nameListFn(
          this.noPrepareTeacherList,
          this.showAllPrepare
        );
      });
      this.prepareLoading = false;
    },
    /**
     * 未授课
     */
    async getNoUseTeachTeacher() {
      let params = Object.assign(
        this.pagingTeachParams,
        { useType: 1 },
        this.timeParams
      );
      this.teachLoading = true;
      await noUseTeacher(params).then((res) => {
        this.totalTeach = res.data.total;
        this.totalTeachPage = res.data.pages;
        this.noTeachTeacherList = (res.data.records || []).map((item) => {
          item._mobile = item.mobile;
          return item;
        });
        this.noTeach = this.nameListFn(
          this.noTeachTeacherList,
          this.showAllTeach
        );
      });
      this.teachLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.analysis-header {
  background: #fff;
  padding: 10px 0;
  margin-bottom: 10px;
  border-radius: 4px;
}
.usage {
  width: 100%;
  background-color: #f6f8fc;
  &_box {
    background-color: #fff;
    margin-top: 10px;
    padding: 22px 26px 20px 20px;
  }
  &_title {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
    &_left {
      align-items: center;
    }
    &_right {
      align-items: center;
      cursor: pointer;
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .text {
        font-size: 13px;
        color: @themeBlue;
        line-height: 17px;
      }
    }
  }
  .analysis-title {
    margin-left: -20px;
  }
}

// /deep/ .el-table__body tr:hover > td {
//   background-color: #f1f6ff !important;
// }
// /deep/ .el-table--scrollable-x .el-table__body-wrapper {
//   overflow-x: hidden;
// }
// /deep/ .el-radio-group {
//   border-radius: 15px;
//   background-color: #f5f6f7;
// }
// /deep/ .el-radio-button__inner {
//   background-color: #f5f6f7;
//   border: 0;
// }
// /deep/ .el-radio-button--small .el-radio-button__inner {
//   padding: 0;
//   font-size: 13px;
//   color: #464646;
//   line-height: 17px;
//   padding: 5px 18px;
//   box-sizing: border-box;
//   border-radius: 15px;
// }
// /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
//   color: #fff;
// }
/deep/ .el-radio-button:first-child:last-child .el-radio-button__inner {
  border-radius: 15px;
}
</style>
