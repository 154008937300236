<template>
  <div class="list">
    <div class="list_table">
      <table>
        <thead>
          <tr>
            <td>{{ $t("el.authDialog.name") }}</td>
            <td>{{ $t("el.forgotPwd.MobileNumber") }}</td>
            <td>{{ $t("el.authDialog.name") }}</td>
            <td>{{ $t("el.forgotPwd.MobileNumber") }}</td>
          </tr>
        </thead>
        <tbody v-if="list.length">
          <tr v-for="(item, index) in list" :key="index">
            <template v-if="index % 2 === 0">
              <td>{{ item.staffName }}</td>
              <td>{{ item._mobile }}</td>
              <td v-if="index + 1 < list.length">
                {{ list[index + 1].staffName }}
              </td>
              <td v-if="index + 1 < list.length">
                {{ list[index + 1]._mobile }}
              </td>
            </template>
          </tr>
        </tbody>
        <tbody v-else class="list_no_data">
          <tr>
            <td colspan="4">{{ $t("el.common.noData") }}</td>
          </tr>
        </tbody>
      </table>
      <!-- <el-table
            :data="nameList"
            border
            :header-cell-style="{background:'#F5F6F7',color: '#323232'}"
          >
            <el-table-column prop="name" :label="$t('el.authDialog.name')" align="center"></el-table-column>
            <el-table-column prop="staffCode" :label="$t('el.schoolResourceManage.workNumber')" align="center"></el-table-column>
            <el-table-column prop="name1" :label="$t('el.authDialog.name')" align="center"></el-table-column>
            <el-table-column prop="staffCode1" :label="$t('el.schoolResourceManage.workNumber')" align="center"></el-table-column>
          </el-table>-->
    </div>
    <div class="list_pagination f-c" v-show="totalPage > 0 && showPage">
      <span class="f-r"
        >{{ $t("el.pagination.totalname") }}{{ totalPage
        }}{{ $t("el.pagination.pages") }} {{ total
        }}{{ $t("el.pagination.items") }}</span
      >
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="currentPage"
        :page-size="20"
        :page-sizes="[20, 40, 60, 100]"
        layout="prev, pager, next, sizes"
        :total="total"
        class="f-r"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "tableList",
  props: {
    list: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    total: {
      type: Number,
      default: 0,
    },
    totalPage: {
      type: Number,
      default: 0,
    },
    showPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {
    /**
     * 分页
     */
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit("handleCurrentChange", val);
    },
    handleSizeChange(val) {
      this.$emit("handleSizeChange", { page: this.currentPage, limit: val });
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  padding-bottom: 16px;
  &_table {
    margin: 0 auto;
    width: 800px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  table,
  table tr th,
  table tr td {
    border: 1px solid #ebebeb;
    height: 50px;
    text-align: center;
  }
  thead {
    background: #f5f6f7;
    color: #323232;
  }
  tbody {
    color: #707276;
    tr:hover {
      background-color: #f1f6ff;
    }
  }
  td {
    width: 200px;
  }
  &_pagination {
    width: 800px;
    margin: 0 auto;
    background: rgba(245, 246, 247, 0.5);
    height: 40px;
    line-height: 40px;
    color: #282828;
    font-size: 12px;
    padding-right: 18px;
    box-sizing: border-box;
    border: 1px solid #ebebeb;
    border-top: 0;
    .f-r {
      vertical-align: middle;
    }
  }
  &_no_data {
    td {
      padding: 30px;
    }
  }
  /deep/ .el-pagination span:not([class*="suffix"]),
  .el-pagination button {
    display: inline-block;
    font-size: 13px;
    min-width: 35.5px;
    height: 28px;
    /* line-height: 40px; */
    line-height: 28px;
    vertical-align: baseline;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}
/deep/ .el-pagination {
  padding: 0;
}
/deep/ .el-pagination button:disabled {
  background: none;
}
/deep/ .el-pagination button {
  height: 40px;
  background: none;
  line-height: 40px;
}
/deep/ .el-pagination .btn-next,
.el-pagination .btn-prev {
  background: none;
}
/deep/ .el-pager li {
  background: none;
  height: 40px;
  line-height: 40px;
  font-weight: 400;
  color: #282828;
}
/deep/ .el-pager li.active {
  font-weight: bold;
  color: @themeBlue;
}
/deep/ .el-pager li:hover {
  font-weight: bold;
  color: @themeBlue;
}
/deep/ .el-icon-more:before {
  content: "...";
}
</style>
