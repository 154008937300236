<template>
  <div>
    <div
      class="toggle-list flex"
      v-show="!showAll && showToggle"
      @click="showAllFn"
    >
      <img src="@/assets/images/icon_open@2x.png" alt class="icon" />
      <span class="text">{{ $t("el.dataAnalysis.unfoldAllRecords") }}</span>
    </div>
    <div class="toggle-list flex" v-show="showAll" @click="showAllFn">
      <img src="@/assets/images/icon_retract@2x.png" alt class="icon" />
      <span class="text">{{ $t("el.dataAnalysis.foldAllRecords") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "toggle-list",
  props: {
    showToggle: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showAll: false,
    };
  },
  methods: {
    showAllFn() {
      this.showAll = !this.showAll;
      this.$emit("showAll", this.type, this.showAll);
    },
  },
};
</script>

<style lang="less" scoped>
.toggle-list {
  align-items: center;
  cursor: pointer;
  .icon {
    width: 12px;
    height: 12px;
    margin-right: 3px;
  }
  .text {
    font-size: 13px;
    color: @themeBlue;
    line-height: 17px;
  }
}
</style>
